import { getScaledValue } from "@rnv/renative";
import React from "react";
import { StyleSheet, Image } from "react-native";
import { Fireside } from "../Icons";

const styles = StyleSheet.create({
  logo: {
    height: getScaledValue(15),
    width: getScaledValue(108)
  }
});

const Logo = () => {
  return <Fireside {...styles.logo} />;
};

export default Logo;
