import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, TouchableOpacity, View, FlatList, NativeSyntheticEvent, NativeScrollEvent } from "react-native";
import { useRecoilValue } from "recoil";
import { epgState, getChannels } from "../../atoms/airing-now/epg";
import LoadingScreen from "../../components/LoadingScreen";
import EpisodeSlider from "./EpisodeSlider";
import { PADDING_VERITCAL, ITEM_HEIGHT, ROW_TRAN_Y } from "./constants";
import Animated from "react-native-reanimated";
import AnimatedViewOpacity from "../../components/Animated/AnimatedViewOpacity";
import { hasWebFocusableUI } from "../../config";
import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import { activeIndexState, setActiveIndex } from "../../atoms/airing-now/activeIndex";
import { isFactorMobile } from "@rnv/renative";

const styles = StyleSheet.create({
  flatlist: {
    backgroundColor: "#111111"
  }
});

const ScreenAiringNow = ({}) => {
  const sliderRef = useRef<any>();
  const channels = useRecoilValue<any>(epgState);
  const { rowIndex, itemIndex, fullScreen } = useRecoilValue<any>(activeIndexState);

  const itemHeight = ITEM_HEIGHT + ROW_TRAN_Y;

  useEffect(() => {
    getChannels();
  }, []);

  const onMomentumScrollEnd = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    console.log("onMomentumScrollEnd", itemHeight, e.nativeEvent.contentOffset.y);
    const index = Math.round(e.nativeEvent.contentOffset.y / itemHeight);
    if (rowIndex !== index) {
      setActiveIndex(index, itemIndex);
    }
  };

  const getItemLayout = (data, index) => {
    return { length: itemHeight, offset: itemHeight * index, index };
  };

  const renderItem = ({ item, index }) => {
    return (
      <TouchableOpacity style={{ flex: 1, marginTop: index > 0 ? ROW_TRAN_Y : 0 }} activeOpacity={1} onFocus={() => sliderRef.current.scrollToIndex({ index, animated: false })}>
        <AnimatedViewOpacity opacity={!fullScreen ? 1 : rowIndex === index ? 1 : 0}>
          <EpisodeSlider
            key={index}
            channel={item}
            rowIndex={index}
            focusKey={`row_${index}`}
            preferredChildFocusKey={`episode_${index}_1`}
            onBecameFocused={() => sliderRef.current.scrollToIndex({ index })}
          />
        </AnimatedViewOpacity>
      </TouchableOpacity>
    );
  };

  if (!channels) {
    return <LoadingScreen />;
  }
  // console.log("Airing Now");
  return (
    <FlatList
      style={styles.flatlist}
      ref={sliderRef}
      contentContainerStyle={{ paddingVertical: PADDING_VERITCAL }}
      showsVerticalScrollIndicator={false}
      scrollEnabled={isFactorMobile}
      snapToInterval={itemHeight}
      decelerationRate="fast"
      scrollEventThrottle={16}
      windowSize={3}
      initialNumToRender={3}
      onMomentumScrollEnd={onMomentumScrollEnd}
      data={channels}
      getItemLayout={getItemLayout}
      renderItem={renderItem}
      nestedScrollEnabled
    />
  );
};

export default hasWebFocusableUI ? withFocusable()(ScreenAiringNow) : ScreenAiringNow;
