import React from "react";
import { View, Text } from "../../../components/Tw";
import { useRecoilValue } from "recoil";
import { activeEpisodePreviewState } from "../../../atoms/airing-now/activeEpisodePreview";
import { isFactorMobile } from "@rnv/renative";

const Description = ({ description }) => {
  const activeEpisodePreview = useRecoilValue<any>(activeEpisodePreviewState);

  return (
    <View>
      <Text className="font-open-sans font-bold text-white mt-6">About the {activeEpisodePreview ? "episode" : "network"}</Text>
      <Text className="font-open-sans text-white sm:text-xl opacity-70" numberOfLines={isFactorMobile ? 0 : 6}>
        {activeEpisodePreview ? activeEpisodePreview.description : description}
      </Text>
    </View>
  );
};

export default Description;
