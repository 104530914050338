import { useState } from "react";
import { useToast } from "react-native-toast-notifications";
import useBackKey from "../useBackKey";
import { useNavigate } from "../useNavigate";
import { useLocation } from "../useLocation";
import { hasWebFocusableUI } from "../../config";

const useExit = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const toast = useToast();
  const [isPending, setIsPending] = useState(false);

  // TODO: Find the correct check
  const canGoBack = () => {
    console.log("canGoBack", location);
    // if (hasWebFocusableUI) {
    //   return history.state.idx > 0;
    // } else {
    //   return true;
    // }

    return location.key !== "default";
  };

  useBackKey(() => {
    if (canGoBack() && !location.pathname.includes("/login")) {
      console.log("-1");
      navigate(-1);
      return true;
    } else {
      if (isPending) {
        if (hasWebFocusableUI) {
          console.log("Exit");
          window.close();
          return false;
        } else {
          console.log("Exit");
          return false;
        }
      }

      setIsPending(true);
      setTimeout(() => setIsPending(false), 3000);
      toast.show("Press back again to exit the app");
      return true;
    }
  }, 0);
};

export default useExit;
