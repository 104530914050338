import React, { useState } from "react";
import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import Content from "./Content";

const FocusableFeed = withFocusable()(Content);

const Feed = ({ feed, itemIndex, onFocus }) => {
  const [active, setActive] = useState(false);

  const handleFocus = () => {
    setActive(true);
    onFocus();
  };

  const handleBlur = () => {
    setActive(false);
  };

  return <FocusableFeed focusKey={`feed_${itemIndex}`} onBecameFocused={handleFocus} onBecameBlurred={handleBlur} feed={feed} active={active} />;
};

export default Feed;
