import React from "react";
import { hasWebFocusableUI, ThemeProvider } from "../config";
import Navigation from "../navigation";
import { Provider } from "react-redux";
import store from "../store";
import { AuthProvider } from "../contexts/FirebaseAuthContext";
import { ToastProvider } from "react-native-toast-notifications";
import { Provider as PaperProvider } from "react-native-paper";
import { FocusProvider } from "../contexts/FocusContext";
import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import theme from "../theme";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import { RecoilDevTools } from "recoil-gear";
import 'core-js/stable';

const App = ({ setFocus }) => {
  return (
    <RecoilRoot>
      <RecoilNexus />
      <RecoilDevTools />
      <Provider store={store}>
        <AuthProvider>
          <ThemeProvider>
            <PaperProvider theme={theme}>
              <ToastProvider>
                <FocusProvider setFocus={setFocus}>
                  <Navigation />
                </FocusProvider>
              </ToastProvider>
            </PaperProvider>
          </ThemeProvider>
        </AuthProvider>
      </Provider>
    </RecoilRoot>
  );
};

export default hasWebFocusableUI ? withFocusable()(App) : App;
