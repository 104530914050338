import React from "react";
import { Navigate, Outlet } from "../Router";
import useAuth from "../../hooks/useAuth";

const GuestGuard = () => {
  const { isAuthenticated } = useAuth();
  return !isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default GuestGuard;
