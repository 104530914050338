import { atom } from "recoil";
import { setRecoil, getRecoil } from "recoil-nexus";

export const layoutState = atom({
  key: "layout",
  default: {
    visible: true
  }
});

export const hideLayout = () => {
  const state = getRecoil(layoutState);
  setRecoil(layoutState, { ...state, visible: false });
};

export const showLayout = () => {
  const state = getRecoil(layoutState);
  setRecoil(layoutState, { ...state, visible: true });
};
