import React from "react";
import { View } from "react-native";
import { Outlet } from "../components/Router";
import { useRecoilState } from "recoil";
import { layoutState } from "../atoms/layout";
import TopBar from "../components/TopBar";

const Layout = () => {
  const [layout] = useRecoilState<any>(layoutState);

  return (
    <View style={{ flex: 1 }}>
      <TopBar visible={layout.visible} />
      <Outlet />
    </View>
  );
};

export default Layout;
