import { getScaledValue } from "@rnv/renative";
import React from "react";
import { View, Image } from "react-native";
import { ICON_LOADING } from "../config";

const LoadingScreen = () => {
  return (
    <View style={{ flex: 1, alignItems: "center", justifyContent: "center", height: "100%", backgroundColor: "black" }}>
      <Image source={ICON_LOADING} style={{ width: getScaledValue(125), aspectRatio: 1 }} />
    </View>
  );
};

export default LoadingScreen;
