import React, { useState } from "react";
import { StyleSheet, View, Image } from "react-native";
import Logo from "../Logo";
import FocusableButton from "../Focusable/FocusableButton";
import useAuth from "../../hooks/useAuth";
import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import { hasWebFocusableUI, GLOW } from "../../config";
import { getScaledValue } from "@rnv/renative";
import { useNavigate } from "../../hooks/useNavigate";
import AnimatedViewOpacity from "../Animated/AnimatedViewOpacity";
import { AiringNow, AiringNowActive, ContentFeed, ContentFeedActive, Profile } from "../Icons";
import useBackKey from "../../hooks/useBackKey";
import { useFocusContext } from "../../contexts/FocusContext";
import { Text } from "react-native-paper";
import { Text as AppText } from "../Tw";

const webStylePatch = hasWebFocusableUI
  ? {
      top: -getScaledValue(60),
      transform: [{ translateY: getScaledValue(60) }]
    }
  : {};
const borderRadius = getScaledValue(100 / 2);
const buttonWidth = getScaledValue(105 / 2);
const buttonHeight = getScaledValue(72 / 2);
const glowWidth = getScaledValue(46 / 2);

const styles = StyleSheet.create({
  topBarContainer: {
    position: "absolute",
    zIndex: 1,
    width: "100%",
    height: getScaledValue(60),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: getScaledValue(40),
    paddingRight: getScaledValue(15),
    ...webStylePatch
  },
  iconsContainer: {
    flexDirection: "row",
    width: getScaledValue(315 / 2),
    height: getScaledValue(36),
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: borderRadius
  },
  button: {
    width: buttonWidth,
    height: buttonHeight,
    borderRadius: borderRadius,
    justifyContent: "center",
    alignItems: "center"
  },
  glow: {
    width: glowWidth,
    height: getScaledValue(20 / 2),
    position: "absolute",
    bottom: -getScaledValue(6 / 2),
    left: (buttonWidth - glowWidth) / 2
  },
  signOut: {
    width: getScaledValue(150 / 2),
    marginRight: getScaledValue(40 / 2)
  }
});

const Path = {
  ContentFeed: "content-feed",
  AiringNow: "airing-now",
  Profile: "profile"
};

const iconSize = {
  width: getScaledValue(32 / 2),
  height: getScaledValue(32 / 2)
};

const TopBar = ({ visible = true, hasFocusedChild, setFocus }) => {
  const navigate = useNavigate();
  const { focusKey, setFocusKey } = useFocusContext();
  const { logout } = useAuth();

  const [activePath, setActivePath] = useState<any>(null);

  useBackKey(() => {
    if (hasWebFocusableUI) {
      if (!hasFocusedChild) {
        setFocus?.();
        console.log("[TopBar] Back key handled");
        return true;
      }
    } else {
      if (!Object.values(Path).includes(focusKey)) {
        setFocusKey(Path.ContentFeed);
        console.log("[TopBar] Back key handled");
        return true;
      }

      console.log("[TopBar] Back key skipped");
      return false;
    }
  }, 1);

  const handleEnterPress = (path) => {
    setActivePath(path);
    navigate(path);
  };

  return (
    <AnimatedViewOpacity style={styles.topBarContainer} opacity={visible ? 1 : 0}>
      <Logo />
      <View style={{ flexDirection: "row" }}>
        {activePath === Path.Profile && (
          <FocusableButton style={styles.signOut} onPress={() => logout()} onEnterPress={() => logout()}>
            <AppText className="text-white text-1.5xl">Sign Out</AppText>
          </FocusableButton>
        )}
        <View style={styles.iconsContainer}>
          <View>
            <FocusableButton
              style={styles.button}
              hasTVPreferredFocus={focusKey === Path.ContentFeed}
              onPress={() => handleEnterPress(Path.ContentFeed)}
              onEnterPress={() => handleEnterPress(Path.ContentFeed)}
            >
              {activePath === Path.ContentFeed ? <ContentFeedActive {...iconSize} /> : <ContentFeed {...iconSize} />}
            </FocusableButton>
            {activePath === Path.ContentFeed && <Image style={styles.glow} source={GLOW} />}
          </View>

          <View>
            <FocusableButton
              style={styles.button}
              hasTVPreferredFocus={focusKey === Path.AiringNow}
              onPress={() => handleEnterPress(Path.AiringNow)}
              onEnterPress={() => handleEnterPress(Path.AiringNow)}
            >
              {activePath === Path.AiringNow ? <AiringNowActive {...iconSize} /> : <AiringNow {...iconSize} />}
            </FocusableButton>
            {activePath === Path.AiringNow && <Image style={styles.glow} source={GLOW} />}
          </View>

          <View>
            <FocusableButton style={styles.button} hasTVPreferredFocus={focusKey === Path.Profile} onPress={() => logout()} onEnterPress={() => logout()}>
              <Profile {...iconSize} />
            </FocusableButton>
            {activePath === Path.Profile && <Image style={styles.glow} source={GLOW} />}
          </View>
        </View>
      </View>
    </AnimatedViewOpacity>
  );
};

export default hasWebFocusableUI ? withFocusable({ trackChildren: true })(TopBar) : TopBar;
