import React, { useEffect, useContext, useMemo, useRef } from "react";
import { View, StyleSheet, Image, FlatList } from "react-native";
import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import { hasWebFocusableUI, ThemeContext } from "../../config";
import { vodState, pushNavigation, popNavigation } from "../../atoms/vod";
import VodDetails from "./VodDetails";
import Player from "../Player";
import { useNavigate } from "../../hooks/useNavigate";
import { playerState, stop } from "../../atoms/player";
import { useRecoilState } from "recoil";
import LoadingScreen from "../../components/LoadingScreen";
import _ from "lodash";
import TopBar from "../../components/TopBar";
import LinearGradient from "../../components/LinearGradient";
import CategorySlider from "./CategorySlider";
import useBackKey from "../../hooks/useBackKey";
import { hideLayout, showLayout } from "../../atoms/layout";

const styles = StyleSheet.create({
  header: {
    display: "flex",
    height: "50%"
  },
  detailsContainer: {
    minHeight: 100,
    marginLeft: 80,
    marginTop: 150
  },
  image: {
    backgroundColor: "#000000",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: -1
  },
  logo: {
    height: 30,
    width: 216,
    marginLeft: 80,
    marginTop: 45
  },
  bottomGradient: {
    position: "absolute",
    top: "50%",
    height: "50%",
    width: "100%",
    zIndex: -1
  },
  leftGradient: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "80%",
    zIndex: -1
  }
});

const CategoryContainer = ({ children }) => {
  if (hasWebFocusableUI) {
    return <div style={{ scrollPaddingTop: "70px", overflowY: "scroll" }}>{children}</div>;
  }

  return <>{children}</>;
};

let timerId;
const ScreenHome = ({ setFocus, pauseSpatialNavigation, resumeSpatialNavigation }) => {
  const navigate = useNavigate();
  const [{ data }, setPlayerState] = useRecoilState<any>(playerState);
  const [vod, setVodState] = useRecoilState<any>(vodState);
  const firstRender = useRef(false);

  const navigations = vod.navigations;
  const curNavigation = _.last(navigations);
  let activeItem = curNavigation?.focusedVod?.item;

  useEffect(() => {
    if (navigations === null) {
      firstRender.current = true;
    }

    if (!activeItem) return;

    if (firstRender.current) {
      timerId = setTimeout(() => {
        navigate("airing-now");
      }, 2 * 1000);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [navigations, activeItem]);

  useBackKey(
    useMemo(
      () => () => {
        if (navigations?.length > 1) {
          popNavigation(setVodState, vod);
          return true;
        }

        if (data) {
          return true;
        }

        console.log("[VOD] Back key skipped");
        return false;
      },
      [navigations, data]
    )
  );

  useEffect(() => {
    const focusedVod = _.last(navigations)?.focusedVod;
    if (focusedVod) {
      const focusKey = `vod_${focusedVod?.rowIndex}_${focusedVod?.itemIndex}`;
      // console.log("setFocus", focusKey);
      setFocus?.(focusKey);
    } else {
      console.log("setFocus");
      setFocus?.(`vod_0_0`);
    }
  }, [navigations?.length]);

  useEffect(() => {
    pushNavigation(setVodState, vod, null);
    setFocus?.(`vod_0_0`);
  }, []);

  useEffect(() => {
    if (data) {
      hideLayout();
      pauseSpatialNavigation?.();
    } else {
      showLayout();
      resumeSpatialNavigation?.();
    }
  }, [data]);

  if (!navigations) {
    return <LoadingScreen />;
  }

  return (
    <View style={{ flex: 1 }}>
      {data && (
        <View style={{ position: "absolute", height: "100%", width: "100%", backgroundColor: "#000", zIndex: 9999 }}>
          <Player
            useVideoJs={false}
            useControls
            data={data}
            onClose={() => {
              stop(setPlayerState);
              return true;
            }}
          />
        </View>
      )}

      <View style={styles.header}>
        <View style={styles.detailsContainer}>
          <VodDetails item={activeItem} />
        </View>
      </View>

      <Image style={styles.image} source={{ uri: activeItem?.backgroundUrl }} />

      <LinearGradient style={styles.bottomGradient} useAngle angle={180} colors={["rgba(17, 17, 17, 0)", "rgba(17, 17, 17, 1)"]} />
      <LinearGradient style={styles.leftGradient} useAngle angle={270} colors={["rgba(0, 0, 0, 0)", "rgba(0, 0, 0, 1)"]} />

      <CategoryContainer>
        <FlatList
          windowSize={2}
          initialNumToRender={3}
          data={curNavigation.rows}
          renderItem={({ item, index }) => {
            return <CategorySlider key={index} rowIndex={index} row={item} />;
          }}
        />
      </CategoryContainer>
    </View>
  );
};

export default hasWebFocusableUI ? withFocusable()(ScreenHome) : ScreenHome;
