import React, { useState, useEffect, useRef, Children } from "react";
import { View, Text, FlatList, StyleSheet, Dimensions, TouchableOpacity, Platform, NativeSyntheticEvent, NativeScrollEvent } from "react-native";
import Animated, { useSharedValue, useAnimatedStyle, useAnimatedScrollHandler, interpolate, withTiming, Extrapolate, Easing } from "react-native-reanimated";
import Episode from "../Episode";
import { hasWebFocusableUI } from "../../../config";
import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import { ITEM_WIDTH, ITEM_HEIGHT, PADDING_HORIZONTAL, PADDING_VERITCAL, ITEM_PADDING_VERTICAL, ITEM_BORDER_RADIUS } from "../constants";
import AnimatedViewOpacity from "../../../components/Animated/AnimatedViewOpacity";
import { showLayout, hideLayout } from "../../../atoms/layout";
import { activeIndexState, setActiveIndex, setFullScreen } from "../../../atoms/airing-now/activeIndex";
import ScalableImage from "../../../components/ScalableImage";
import { getScaledValue } from "@rnv/renative";
import { useRecoilValue } from "recoil";
import { isFactorMobile } from "@rnv/renative";

const SCREEN_WIDTH = Dimensions.get("window").width;
const SCREEN_HEIGHT = Dimensions.get("window").height;
const SPACER = PADDING_HORIZONTAL;

const EpisodeSlider = ({ channel, rowIndex, setFocus }) => {
  const episodes: any[] = [];
  const episodePreview = {};
  episodes.push(episodePreview);
  episodes.push(channel.currentEpisode);
  episodes.push(...channel.upcomingEpisodes);

  useEffect(() => {
    if (hasWebFocusableUI && rowIndex === 0) {
      setFocus();
    }
  }, []);

  const activeItem = useRecoilValue<any>(activeIndexState);

  const sliderRef = useRef<any>();
  const [logoOpacity, setLogoOpacity] = useState(1);
  const tranX = useSharedValue(0);

  const onScroll = useAnimatedScrollHandler({
    onScroll: (event) => {
      tranX.value = event.contentOffset.x;
    }
  });

  const onMomentumScrollEnd = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    const index = Math.round(e.nativeEvent.contentOffset.x / ITEM_WIDTH);
    if (activeItem.itemIndex !== index) {
      setActiveIndex(rowIndex, index);
    }
  };

  const getItemLayout = (data, index) => {
    return { length: ITEM_WIDTH, offset: ITEM_WIDTH * index, index };
  };

  const renderItem = ({ item, index }) => {
    if (index > 1 && !item?.playbackUrl) {
      return null;
    } else {
      return (
        <AnimatedItem key={index} index={index} tranX={tranX}>
          {(toggleFullScreen) => (
            <AnimatedViewOpacity opacity={activeItem.rowIndex == rowIndex && activeItem.itemIndex === index ? 1 : activeItem.fullScreen ? 0 : isFactorMobile ? 1 : 0.4}>
              <Episode
                episode={item}
                channel={channel}
                isLive={index === 1}
                isFullScreen={activeItem.fullScreen}
                itemIndex={index}
                rowIndex={rowIndex}
                sliderRef={sliderRef}
                onActiveIndexChange={() => {
                  if (index === 1 && logoOpacity === 0) {
                    setLogoOpacity(1);
                  }
                  if (index === 0) {
                    setLogoOpacity(0);
                  }
                  setActiveIndex(rowIndex, index);
                }}
                onEnterFullScreen={() => {
                  toggleFullScreen(true);
                  hideLayout();
                  setFullScreen(true);
                }}
                onExitFullScreen={() => {
                  toggleFullScreen(false);
                  showLayout();
                  setFullScreen(false);
                }}
                onToggleFullScreen={() => {
                  const isFullScreen = toggleFullScreen(null);
                  isFullScreen ? hideLayout() : showLayout();
                  setFullScreen(isFullScreen);
                }}
              />
            </AnimatedViewOpacity>
          )}
        </AnimatedItem>
      );
    }
  };

  return (
    <>
      <Animated.FlatList
        ref={sliderRef}
        // style={{ overflow: "visible" }}
        contentContainerStyle={{ paddingHorizontal: SPACER }}
        showsHorizontalScrollIndicator={false}
        scrollEnabled={isFactorMobile}
        snapToInterval={ITEM_WIDTH}
        decelerationRate="fast"
        horizontal
        scrollEventThrottle={16}
        windowSize={3}
        initialNumToRender={3}
        onScroll={onScroll}
        onMomentumScrollEnd={onMomentumScrollEnd}
        getItemLayout={getItemLayout}
        data={episodes}
        renderItem={renderItem}
        initialScrollIndex={1}
        nestedScrollEnabled
      />
      <AnimatedNetworkLogo thumb={channel.thumb} opacity={activeItem.fullScreen ? 0 : logoOpacity} />
    </>
  );
};

const AnimatedNetworkLogo = ({ opacity, thumb }) => {
  const size = getScaledValue(114 / 2);

  return (
    <AnimatedViewOpacity
      style={{
        position: "absolute",
        left: getScaledValue(20 / 2),
        top: SCREEN_HEIGHT / 2 - size / 2,
        width: size,
        height: size,
        justifyContent: "center",
        backgroundColor: "#1c2025",
        borderRadius: getScaledValue(14 / 2)
      }}
      config={{ easing: Easing.bezier(0.64, 0, 0.78, 0) }}
      opacity={opacity}
    >
      <ScalableImage height={size} width={size} source={{ uri: thumb }} />
    </AnimatedViewOpacity>
  );
};

const AnimatedItem = ({ index, tranX, children }) => {
  const itemWidth = useSharedValue(ITEM_WIDTH);
  const itemHeight = useSharedValue(ITEM_HEIGHT);
  const paddingY = useSharedValue(ITEM_PADDING_VERTICAL);
  const borderRadius = useSharedValue(ITEM_BORDER_RADIUS);
  // const opacity = useSharedValue(0.4);

  const rStyle = useAnimatedStyle(() => {
    const scale = interpolate(tranX.value, [(index - 1) * ITEM_WIDTH, (index - 0) * ITEM_WIDTH, (index + 1) * ITEM_WIDTH], [0.9, 1, 0.9], Extrapolate.CLAMP);
    const scaleOpacity = interpolate(tranX.value, [(index - 1) * ITEM_WIDTH, (index - 0) * ITEM_WIDTH, (index + 1) * ITEM_WIDTH], [0.4, 1, 0.4], Extrapolate.CLAMP);

    // console.log("scale", scale);
    return {
      transform: [{ scale }],
      width: withTiming(itemWidth.value),
      height: withTiming(itemHeight.value),
      paddingVertical: withTiming(paddingY.value)
      // opacity: scaleOpacity
    };
  }, []);

  const rInnerStyle = useAnimatedStyle(() => {
    return {
      borderRadius: withTiming(borderRadius.value)
    };
  }, []);

  const toggleFullScreen = (isFullScreen) => {
    if (isFullScreen === null) {
      isFullScreen = !(itemWidth.value === SCREEN_WIDTH);
    }

    itemWidth.value = isFullScreen ? SCREEN_WIDTH : ITEM_WIDTH;
    itemHeight.value = isFullScreen ? SCREEN_HEIGHT : ITEM_HEIGHT;
    paddingY.value = isFullScreen ? 0 : ITEM_PADDING_VERTICAL;
    borderRadius.value = isFullScreen ? 0 : ITEM_BORDER_RADIUS;

    return isFullScreen;
  };

  return (
    <View style={{ width: ITEM_WIDTH, height: ITEM_HEIGHT, justifyContent: "center", alignItems: "center" }}>
      <Animated.View style={[{ flex: 1 }, rStyle]}>
        <Animated.View style={[{ flex: 1, overflow: "hidden" }, rInnerStyle]}>{children(toggleFullScreen)}</Animated.View>
      </Animated.View>
    </View>
  );
};

export default hasWebFocusableUI ? withFocusable()(EpisodeSlider) : EpisodeSlider;
