import { atom } from "recoil";
import { getRecoil, setRecoil } from "recoil-nexus";

export const activeIndexState = atom({
  key: "activeIndex",
  default: {
    rowIndex: 0,
    itemIndex: 1,
    fullScreen: false
  }
});

export const setActiveIndex = (rowIndex, itemIndex) => {
  setRecoil(activeIndexState, { rowIndex, itemIndex, fullScreen: false });
};

export const setFullScreen = (fullScreen) => {
  const state = getRecoil(activeIndexState);
  setRecoil(activeIndexState, { ...state, fullScreen });
};
