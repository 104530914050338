import { atom } from "recoil";
import { setRecoil } from "recoil-nexus";
import axios from "../utils/axios";
import _ from "lodash";

const channelIds = [
  "617369ee-d1ff-42a0-a1cd-ec0715150cc2",
  "8b84d7f9-dac0-41d5-9ad2-3c64faef8ca8",
  "3fb0e261-c013-47ec-80d6-679245859da2",
  "fedf562d-585c-409f-a4a6-b6862da26187",
  "a14bda36-45b7-43d3-81a4-082931682a90",
  "1388bfde-d274-4cc7-acc5-63b175e55d66",
  "b1a24d43-a1d7-4f2b-a1dd-604568c47f2f",
  "86e6dd5e-7f62-45d1-bc75-19f96d31c2e1",
  "53c58bfa-d60c-40ee-902b-9fc9f26a46d2",
  "cab42c46-e59e-4390-a8df-6d257a3e2fe2",
  "2aaa8f44-b568-4599-bbae-8e355583a81f",
  "de7ff908-411f-4023-8621-3d23f34bccc2",
  "85ed3150-bd14-4d1a-8aac-d1b4762291d7"
];

export const contentFeedState = atom({
  key: "contentFeed",
  default: null
});

export const getFeeds = async () => {
  const { data } = await axios.get(`/programGuide`);
  const filteredData = data.filter(
    (channel) => channel.currentEpisode && (channel.providerDisplayName === "Shared" || channel.providerDisplayName === "Custom" || channelIds.includes(channel.id))
  );
  setRecoil(contentFeedState, filteredData as any);
  // setRecoil(epgState, data as any);
};
