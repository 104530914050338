import { atom } from "recoil";
import { setRecoil } from "recoil-nexus";
import axios from "../../utils/axios";

export const epgState = atom({
  key: "epg",
  default: null
});

export const getChannels = async () => {
  const { data } = await axios.get(`/programGuide`);
  const filteredData = data.filter((channel) => channel.currentEpisode && channel.upcomingEpisodes);
  setRecoil(epgState, filteredData as any);
};
