import { combineReducers } from "@reduxjs/toolkit";
import { reducer as vodReducer } from "../slices/vod";
import { reducer as playerReducer } from "../slices/player";
import { reducer as stremiumUserReducer } from "../slices/stremiumUser";

const rootReducer = combineReducers({
  vod: vodReducer,
  player: playerReducer,
  stremiumUser: stremiumUserReducer
});

export default rootReducer;
