import { getScaledValue } from "@rnv/renative";
import React from "react";
import { StyleSheet, View } from "react-native";

const styles = StyleSheet.create({
  focused: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    borderColor: "#FFF",
    borderWidth: getScaledValue(3 / 2),
    boxShadow: "0px 0px 13px 4px rgba(255, 255, 255, 0.37)"
  }
});

const ContentOutline = ({ active, containerProps = null }: any) => {
  return (
    <>
      <View style={[active && { borderRadius: containerProps?.style?.borderRadius ?? 0 }, active && styles.focused]} />
      {containerProps.children}
    </>
  );
};

export default ContentOutline;
