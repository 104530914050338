export default [{
                              fontFamily: 'Inter-Light',
                              file: require('/workspace/appConfigs/base/fonts/Inter-Light.ttf'),
                          },{
                              fontFamily: 'OpenSans-400',
                              file: require('/workspace/appConfigs/base/fonts/OpenSans-400.ttf'),
                          },{
                              fontFamily: 'OpenSans-700',
                              file: require('/workspace/appConfigs/base/fonts/OpenSans-700.ttf'),
                          },{
                              fontFamily: 'OpenSans-800',
                              file: require('/workspace/appConfigs/base/fonts/OpenSans-800.ttf'),
                          },{
                              fontFamily: 'OpenSans-All',
                              file: require('/workspace/appConfigs/base/fonts/OpenSans-All.ttf'),
                          },{
                              fontFamily: 'OpenSans',
                              file: require('/workspace/appConfigs/base/fonts/OpenSans.ttf'),
                          },{
                              fontFamily: 'Work-Sans',
                              file: require('/workspace/appConfigs/base/fonts/Work-Sans.ttf'),
                          },{
                              fontFamily: 'WorkSans-400',
                              file: require('/workspace/appConfigs/base/fonts/WorkSans-400.ttf'),
                          },{
                              fontFamily: 'WorkSans-500',
                              file: require('/workspace/appConfigs/base/fonts/WorkSans-500.ttf'),
                          },{
                              fontFamily: 'WorkSans',
                              file: require('/workspace/appConfigs/base/fonts/WorkSans.ttf'),
                          },{
                              fontFamily: 'AntDesign',
                              file: require('/workspace/node_modules/react-native-vector-icons/Fonts/AntDesign.ttf'),
                          },{
                              fontFamily: 'Entypo',
                              file: require('/workspace/node_modules/react-native-vector-icons/Fonts/Entypo.ttf'),
                          },{
                              fontFamily: 'EvilIcons',
                              file: require('/workspace/node_modules/react-native-vector-icons/Fonts/EvilIcons.ttf'),
                          },{
                              fontFamily: 'Feather',
                              file: require('/workspace/node_modules/react-native-vector-icons/Fonts/Feather.ttf'),
                          },{
                              fontFamily: 'FontAwesome',
                              file: require('/workspace/node_modules/react-native-vector-icons/Fonts/FontAwesome.ttf'),
                          },{
                              fontFamily: 'FontAwesome5_Brands',
                              file: require('/workspace/node_modules/react-native-vector-icons/Fonts/FontAwesome5_Brands.ttf'),
                          },{
                              fontFamily: 'FontAwesome5_Regular',
                              file: require('/workspace/node_modules/react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf'),
                          },{
                              fontFamily: 'FontAwesome5_Solid',
                              file: require('/workspace/node_modules/react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf'),
                          },{
                              fontFamily: 'Fontisto',
                              file: require('/workspace/node_modules/react-native-vector-icons/Fonts/Fontisto.ttf'),
                          },{
                              fontFamily: 'Foundation',
                              file: require('/workspace/node_modules/react-native-vector-icons/Fonts/Foundation.ttf'),
                          },{
                              fontFamily: 'Ionicons',
                              file: require('/workspace/node_modules/react-native-vector-icons/Fonts/Ionicons.ttf'),
                          },{
                              fontFamily: 'MaterialCommunityIcons',
                              file: require('/workspace/node_modules/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'),
                          },{
                              fontFamily: 'MaterialIcons',
                              file: require('/workspace/node_modules/react-native-vector-icons/Fonts/MaterialIcons.ttf'),
                          },{
                              fontFamily: 'Octicons',
                              file: require('/workspace/node_modules/react-native-vector-icons/Fonts/Octicons.ttf'),
                          },{
                              fontFamily: 'SimpleLineIcons',
                              file: require('/workspace/node_modules/react-native-vector-icons/Fonts/SimpleLineIcons.ttf'),
                          },{
                              fontFamily: 'Zocial',
                              file: require('/workspace/node_modules/react-native-vector-icons/Fonts/Zocial.ttf'),
                          },];