import React, { useEffect, useState } from "react";
import Content from "./Content";
import SpatialNavigation from "@noriginmedia/react-spatial-navigation/dist/spatialNavigation";
import { ITEM_HEIGHT, ROW_TRAN_Y, FULL_SCREEN_TIMER_DURATION } from "../constants";
import { View } from "react-native";
import ChannelPreviewContent from "../ChannelPreview";
import FocusablePlaceholder from "../../../components/Focusable/FocusablePlaceholder";
import useBackKey from "../../../hooks/useBackKey";
import { useFocusContext } from "../../../contexts/FocusContext";
import { setActiveEpisodePreview } from "../../../atoms/airing-now/activeEpisodePreview";
import useTimeout from "../../../hooks/useTimeout";

export default ({ episode, channel, isLive, isFullScreen, sliderRef, itemIndex, rowIndex, onEnterFullScreen, onExitFullScreen, onToggleFullScreen, onActiveIndexChange }) => {
  const { setFocus } = useFocusContext();

  const [active, setActive] = useState(false);

  const focusKey = `episode_${rowIndex}_${itemIndex}`;

  useTimeout(
    () => {
      if (isLive && active) {
        onEnterFullScreen();
      }
    },
    FULL_SCREEN_TIMER_DURATION,
    [isLive, active]
  );

  useBackKey(() => {
    if (isFullScreen && active) {
      onExitFullScreen();
      setFocus(focusKey);
      setActiveEpisodePreview(null);
      return true;
    }
    return false;
  });

  const handleFocus = () => {
    setActive(true);
    onActiveIndexChange();
    sliderRef.current.scrollToIndex({ index: itemIndex });
  };

  const handleBlur = () => {
    if (itemIndex === 0 && isFullScreen) {
      return;
    }

    setActive(false);
    onExitFullScreen();
  };

  const handeEnter = () => {
    onToggleFullScreen();
  };

  const renderFocusablePlaceholder = () => (
    <FocusablePlaceholder
      style={{ width: 1, height: 1, left: "50%", top: "50%" }}
      focusKey={focusKey}
      onBecameFocused={handleFocus}
      onBecameBlurred={handleBlur}
      onArrowPress={(direction, props) => {
        // TODO: Double check
        const rowKeys = Object.keys(SpatialNavigation.focusableComponents).filter((n) => n.startsWith("row_"));
        rowKeys.forEach((n) => (SpatialNavigation.focusableComponents[n].layout.height = ITEM_HEIGHT + ROW_TRAN_Y - 1));
      }}
      onEnterPress={handeEnter}
    />
  );

  return (
    <View style={{ flex: 1 }}>
      {itemIndex === 0 ? (
        <ChannelPreviewContent channel={channel} active={active} isFullScreen={isFullScreen}>
          {renderFocusablePlaceholder()}
        </ChannelPreviewContent>
      ) : (
        <Content episode={episode} channel={channel} isLive={isLive} active={active} isFullScreen={isFullScreen}>
          {renderFocusablePlaceholder()}
        </Content>
      )}
    </View>
  );
};
