import { DefaultTheme } from "react-native-paper";
console.log(DefaultTheme);
export default {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    text: "#FFF"
  },
  dark: true
};
