import React, { createContext, useContext, useEffect, useState } from "react";
import { hasWebFocusableUI } from "../config";

export type FocusState = {
  focusKey: string;
  setFocusKey: (focusKey) => void;
  /** [Web] No-tracking */
  setFocus: (focusKey) => void;
};

const FocusContext = createContext<FocusState>({ focusKey: "", setFocusKey: () => {}, setFocus: () => {} });

export const FocusProvider = ({ children, setFocus }) => {
  const [focusKey, setFocusKey] = useState("");

  if (hasWebFocusableUI) {
    useEffect(() => {
      setFocus(focusKey);
    }, [focusKey]);
  }

  return (
    <FocusContext.Provider
      value={{
        focusKey,
        setFocusKey,
        setFocus
      }}
    >
      {children}
    </FocusContext.Provider>
  );
};

export function useFocusContext() {
  return useContext(FocusContext);
}
