import React, { useState, useEffect } from "react";
import { View, Text, Image } from "../../../components/Tw";
import ScalableImage from "../../../components/ScalableImage";
import { LOCK } from "../../../config";
import Timeline from "./Timeline";
import Tiles from "./Tiles";
import BackgroundGradient from "./BackgroundGradient";
import axios from "../../../utils/axios";
import AnimatedViewOpacity from "../../../components/Animated/AnimatedViewOpacity";
import { Easing } from "react-native-reanimated";
import { useFocusContext } from "../../../contexts/FocusContext";
import Description from "./Description";

const ChannelPreviewContent = ({ children: focusablePlaceHolder = <></>, channel, active, isFullScreen }) => {
  const currentEpisode = channel.currentEpisode;
  const upcomingEpisodes = channel.upcomingEpisodes.slice(0, 2);

  const { setFocus } = useFocusContext();

  const [touched, setTouched] = useState(false);
  const [backgroundColors, setBackgroundColors] = useState({
    brandColor: null,
    darkerBrandColor: null
  });

  const defaultFocusKey = `airing-now-${currentEpisode.vodAssetId}`;

  useEffect(() => {
    axios.get("/brandColor?channelId=" + channel.id).then((result) => {
      setBackgroundColors(result.data);
    });
  }, []);

  useEffect(() => {
    if (active) setTouched(true);
  }, [active]);

  useEffect(() => {
    if (isFullScreen && active) {
      setFocus(defaultFocusKey);
    }
  }, [isFullScreen]);

  // console.log("PreviewContent");
  return (
    <View className="flex-row grow shrink" pointerEvents={isFullScreen ? "auto" : "none"}>
      {focusablePlaceHolder}

      <View className="basis-3/5 p-6 rounded-2xl overflow-hidden justify-between">
        <BackgroundGradient brandColor={backgroundColors.brandColor} darkerBrandColor={backgroundColors.darkerBrandColor} />
        <ScalableImage height={100} source={{ uri: channel.thumb }} />
        <Image className="absolute" style={{ width: 30, height: 29, top: 38, right: 42 }} source={LOCK} />
        {active && (
          <AnimatedViewOpacity style={{ flex: undefined }} config={{ easing: Easing.bezier(0.64, 0, 0.78, 0) }} opacity={1}>
            <Tiles currentEpisode={currentEpisode} upcomingEpisodes={channel.upcomingEpisodes} defaultFocusKey={defaultFocusKey} focusable={isFullScreen && active} />
          </AnimatedViewOpacity>
        )}
      </View>

      <View className="basis-2/5 justify-between pt-12 pl-12">
        <View>
          <Text className="font-open-sans font-bold text-white text-6xl">{channel.title}</Text>
          <View style={{ flexDirection: "row", alignItems: "center", marginTop: 12 }}>
            <Image style={{ width: 18, height: 18 }} source={LOCK} />
            <Text className="font-open-sans text-white text-lg">You are a member</Text>
          </View>
          <Description description={channel.description} />
        </View>
        <View className="mb-12">
          <Timeline
            currentEpisode={currentEpisode}
            upcomingEpisodes={upcomingEpisodes}
            brandColor={backgroundColors.brandColor}
            darkerBrandColor={backgroundColors.darkerBrandColor}
          />
        </View>
      </View>
    </View>
  );
};

export default React.memo(ChannelPreviewContent, (prevProps, nextProps) => {
  return prevProps.active === nextProps.active && prevProps.isFullScreen === nextProps.isFullScreen;
});
