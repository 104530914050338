import React from "react";
import { View } from "../../../components/Tw";
import LinearGradient from "../../../components/LinearGradient";

const BackgroundGradient = ({ brandColor, darkerBrandColor, ...rest }) => {
  return (
    <View className="absolute inset-0 -z-10" {...rest}>
      <LinearGradient style={{ flex: 1 }} useAngle angle={116} colors={[`${darkerBrandColor ?? "#000"}`, `${brandColor ?? "#000"}`]} />
    </View>
  );
};

export default BackgroundGradient;
