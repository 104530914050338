import React from "react";
import { Navigate, Outlet } from "../Router";
import useAuth from "../../hooks/useAuth";

const AuthGuard = () => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <Outlet /> : <Navigate to="/auth/login" />;
};

export default AuthGuard;
