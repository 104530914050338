export default {
  apiKey: "AIzaSyCP8NVCxcZbk76zSe1C-9N-oSS8Uzrfgmk",
  authDomain: "fireside-prod-285316.firebaseapp.com",
  databaseURL: "https://fireside-prod-285316.firebaseio.com",
  projectId: "fireside-prod-285316",
  storageBucket: "fireside-prod-285316.appspot.com",
  messagingSenderId: "659804565049",
  appId: "1:659804565049:web:82063675aaa36eae06e7e8",
  measurementId: "G-QLYSMV10RZ",
};
