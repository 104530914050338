import "../styles/tailwind.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "../app";

// react native web: ReferenceError: __DEV__ is not defined
(window as any).__DEV__ = process.env.NODE_ENV !== "production";
(window as any)._frameTimestamp = null;

ReactDOM.render(<App />, document.getElementById("root"));
