import React, { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { Text, Image, ImageBackground, View } from "../../../components/Tw";
import axios from "../../../utils/axios";
import LinearGradient from "../../../components/LinearGradient";
import AnimatedViewOpacity from "../../../components/Animated/AnimatedViewOpacity";
import { getScaledValue } from "@rnv/renative";
import EpisodeHeader from "../../../components/EpisodeHeader";
import Player from "../../../screens/Player";
import { LIVE_NOW_BLUR, LIVE_NOW_ANIMATION_NO_BLUR, REACTION_TRAY } from "../../../config";
import { isFactorMobile } from "@rnv/renative";

const styles = StyleSheet.create({
  bgGradient: {
    position: "absolute",
    top: "25%",
    width: "100%",
    height: "100%"
  }
});

const Content = ({ children: focusablePlaceHolder, episode, channel, isLive, active, isFullScreen }: any) => {
  const [url, setUrl] = useState(null);
  const opacity = isFullScreen ? 0 : 1;

  useEffect(() => {
    const loadChannel = async () => {
      const response = await axios.get(`/playback/channel/${channel.id}`);
      setUrl(response.data.url);
    };

    if (isLive && active) {
      loadChannel();
    } else if (active && episode.playbackUrl) {
      setUrl(episode.playbackUrl);
      console.log("Setting playback to " + episode.playbackUrl);
    } else {
      setUrl(null);
    }
  }, [active]);

  // console.log("Content");
  return (
    <ImageBackground className="w-full h-full" source={{ uri: episode.primaryImageUrl }}>
      {focusablePlaceHolder}

      {active && url && <Player useVideoJs useCoverMode data={{ url }} />}

      <AnimatedViewOpacity style={styles.bgGradient} opacity={opacity}>
        <LinearGradient style={{ flex: 1 }} />
      </AnimatedViewOpacity>

      <AnimatedViewOpacity style={{ position: "absolute" }} opacity={opacity}>
        {isLive && (
          <ImageBackground source={LIVE_NOW_BLUR}>
            <Image style={{ width: 198, height: 76.5 }} source={LIVE_NOW_ANIMATION_NO_BLUR} />
          </ImageBackground>
        )}
      </AnimatedViewOpacity>

      <AnimatedViewOpacity opacity={opacity}>
        <View className="absolute bottom-0 w-full px-16 pb-16 hidden sm:flex">
          <EpisodeHeader seriesLogo={episode.primaryImageUrl} seriesTitle={episode.title} episodeTitle={episode.episodeTitle} />
          <Text className="text-white opacity-80 w-2/4 text-xl" numberOfLines={4} ellipsizeMode="tail">
            {episode.description}
          </Text>
        </View>
        <Image className="absolute" style={{ right: 42, bottom: 42, width: isFactorMobile ? 160 : 320, height: isFactorMobile ? 40 : 80 }} source={REACTION_TRAY} />
      </AnimatedViewOpacity>
    </ImageBackground>
  );
};

export default React.memo(Content, (prevProps, nextProps) => {
  return prevProps.active === nextProps.active && prevProps.isFullScreen === nextProps.isFullScreen;
});
