import { createSlice } from "@reduxjs/toolkit";
import axios from "../utils/axios";
import _ from "lodash";

const initialState = {
  navigations: null,
  navigationsCache: {}
};

const slice = createSlice({
  name: "vod",
  initialState,
  reducers: {
    setNavigations(state, action: any) {
      state.navigations = action.payload.navigations;
      state.navigationsCache = action.payload.navigationsCache;
    },
    setSelectedVod(state: any, action: any) {
      state.navigations[state.navigations.length - 1] = { ...state.navigations[state.navigations.length - 1], focusedVod: action.payload };
    }
  }
});

export const reducer = slice.reducer;

export const pushNavigation = (id) => async (dispatch, getState) => {
  if (id === null) {
    dispatch(gotoNavigationPath([]));
    return;
  }

  let curNavigations = getState().vod.navigations;
  const paths = curNavigations.map((n) => n.id);
  paths.push(id);
  dispatch(gotoNavigationPath(paths));
};

export const popNavigation = () => async (dispatch, getState) => {
  let curNavigations = getState().vod.navigations;
  const paths = curNavigations.map((n) => n.id);
  paths.pop();
  dispatch(gotoNavigationPath(paths));
};

export const gotoNavigationPath = (paths) => async (dispatch, getState) => {
  let curNavigations = getState().vod.navigations;
  let curNavigationsCache = getState().vod.navigationsCache;

  let homeNavigation;
  if (!curNavigations) {
    const response = await axios.get("/navigation");
    const navigation = { id: null, ...response.data };
    homeNavigation = navigation;
  } else {
    homeNavigation = curNavigations[0];
  }

  const newNavigations = [homeNavigation];
  const newNavigationsCache = { ...curNavigationsCache };

  let ids = paths.filter((n) => n);
  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    let navigation = curNavigationsCache[id];
    if (!navigation) {
      const response = await axios.get(`/navigation/${id}`);
      navigation = { id, ...response.data };
    }

    newNavigations.push(navigation);
    newNavigationsCache[id] = navigation;
  }

  let firstVod;
  let focusedVod = _.last(newNavigations)?.focusedVod;
  if (focusedVod) {
    firstVod = focusedVod.item;
  } else {
    firstVod = newNavigations?.[newNavigations.length - 1].rows?.[0].items?.[0];
    focusedVod = { item: firstVod, rowIndex: 0, itemIndex: 0 };
  }

  dispatch(slice.actions.setNavigations({ navigations: newNavigations, navigationsCache: newNavigationsCache }));
};

export const setSelectedVod = (item, rowIndex, itemIndex) => async (dispatch) => {
  dispatch(slice.actions.setSelectedVod({ item, rowIndex, itemIndex }));
};

export default slice;
