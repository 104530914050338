import React from "react";
import { Text as RnText, TextProps } from "react-native";
import { StyledComponent } from "nativewind";

const FontMap = {
  "font-open-sans": "OpenSans",
  "font-work-sans": "WorkSans"
};

const WeightMap = {
  "font-light": "300",
  "font-normal": "400",
  "font-medium": "500",
  "font-semibold": "600",
  "font-bold": "700",
  "font-extrabold": "800"
};

const SupportedFonts = Object.keys(FontMap);
const SupportedWeights = Object.keys(WeightMap);

export type AppTextProps = TextProps & { className?: string };

const AppText = (props: AppTextProps) => {
  const fontSettings = props.className ? props.className.split(" ").filter((n) => n.startsWith("font-")) : [];

  const font = fontSettings.find((n) => SupportedFonts.includes(n)) || "font-open-sans";
  const weight = fontSettings.find((n) => SupportedWeights.includes(n)) || "font-normal";

  const fontFamily = `${FontMap[font]}-${WeightMap[weight]}`;

  return <StyledComponent component={RnText} {...props} style={[props.style, { fontFamily }]} />;
};

export default React.memo(AppText);
