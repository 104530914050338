import React, { useState, useEffect } from "react";
import { Dimensions, StyleSheet, View, Image, ImageBackground } from "react-native";
import axios from "../../../utils/axios";
import Player from "../../Player";

const { width, height } = Dimensions.get("window");
const styles = StyleSheet.create({
  feedContainer: {
    width,
    height
  },
  image: {
    width,
    height
  }
});

const Content = ({ children, feed, active }) => {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    const loadChannel = async () => {
      const response = await axios.get(`/playback/channel/${feed.id}`);
      setUrl(response.data.url);
    };

    if (active) {
      loadChannel();
    } else {
      setUrl(null);
    }
  }, [active]);

  return (
    <View style={styles.feedContainer}>
      {children}
      <ImageBackground style={styles.image} source={{ uri: feed.currentEpisode.primaryImageUrl }}>
        {active && url && <Player data={{ url }} />}
      </ImageBackground>
    </View>
  );
};

export default Content;
