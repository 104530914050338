import { useEffect } from "react";

const useTimeout = (callback: () => void, delay: number, deps: any[] = []) => {
  useEffect(() => {
    let timerId = setTimeout(() => callback(), delay);

    return () => {
      clearTimeout(timerId);
    };
  }, [...deps]);
};

export default useTimeout;
