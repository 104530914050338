import React, { memo } from "react";
import Svg, { Path, Defs, LinearGradient, Stop } from "react-native-svg";

const ContentFeedActive = (props) => (
  <Svg width={props.width} height={props.height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M15.084 2.038 3.251 11.149a1.5 1.5 0 0 0-.585 1.189v16.828a1.5 1.5 0 0 0 1.5 1.5h5.829a1.5 1.5 0 0 0 1.5-1.5V17.624a1.5 1.5 0 0 1 1.5-1.5h5.919a1.5 1.5 0 0 1 1.5 1.5v11.542a1.5 1.5 0 0 0 1.5 1.5h5.919a1.5 1.5 0 0 0 1.5-1.5V12.338a1.5 1.5 0 0 0-.585-1.189L16.914 2.038a1.5 1.5 0 0 0-1.83 0Z"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M15.084 2.038 3.251 11.149a1.5 1.5 0 0 0-.585 1.189v16.828a1.5 1.5 0 0 0 1.5 1.5h5.829a1.5 1.5 0 0 0 1.5-1.5V17.624a1.5 1.5 0 0 1 1.5-1.5h5.919a1.5 1.5 0 0 1 1.5 1.5v11.542a1.5 1.5 0 0 0 1.5 1.5h5.919a1.5 1.5 0 0 0 1.5-1.5V12.338a1.5 1.5 0 0 0-.585-1.189L16.914 2.038a1.5 1.5 0 0 0-1.83 0Z"
      stroke="url(#a)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Defs>
      <LinearGradient id="a" x1={32.547} y1={30.915} x2={3.747} y2={-1.785} gradientUnits="userSpaceOnUse">
        <Stop stopColor="#D67BBC" />
        <Stop offset={1} stopColor="#1E58F0" />
      </LinearGradient>
    </Defs>
  </Svg>
);

const Memo = memo(ContentFeedActive);
export default Memo;
