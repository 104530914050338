import React from "react";
import { Pressable, ButtonProps } from "react-native";
import { withFocusable, FocusableWrapperProps } from "@noriginmedia/react-spatial-navigation";
import Content from "./Content";

const ButtonTv: React.FC<ButtonProps & FocusableWrapperProps> = (props) => {
  return (
    <Pressable
      {...props}
      onFocus={() => {
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
      }}
      onPress={() => {
        props.setFocus();
        props.onPress();
      }}
    >
      {({ hovered }) => <Content active={props.focused || hovered} containerProps={props} />}
    </Pressable>
  );
};

export default withFocusable()(ButtonTv);
