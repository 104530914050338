import React, { useRef } from "react";
import { StyleSheet, FlatList, View } from "react-native";
import { Text } from "react-native-paper";
import { hasWebFocusableUI } from "../../../config";
import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import { ITEM_WIDTH } from "../constants";
import Item from "../Item";
import { pushNavigation, vodState } from "../../../atoms/vod";
import { playerState, playVod } from "../../../atoms/player";
import { useRecoilState } from "recoil";
import _ from "lodash";
import { getScaledValue } from "@rnv/renative";

const styles = StyleSheet.create({
  row: {
    marginTop: getScaledValue(24 / 2),
    marginLeft: getScaledValue(80 / 2)
  },
  rowTitle: {
    marginBottom: getScaledValue(5 / 2),
    color: "#FFF",
    fontSize: getScaledValue(24 / 2),
    fontWeight: "bold",
    textShadowColor: "rgba(0, 0, 0, 0.75)",
    textShadowOffset: { width: 2, height: 2 },
    textShadowRadius: getScaledValue(10 / 2)
  }
});

const CategorySlider = ({ row, rowIndex }) => {
  const [{ data }, setPlayerState] = useRecoilState<any>(playerState);
  const [vod, setVodState] = useRecoilState<any>(vodState);
  const navigations = vod.navigations;
  const sliderRef = useRef<any>();

  const curNavigation = _.last(navigations);
  let activeItem = curNavigation?.focusedVod?.item;

  const handlePress = (_, item, focusedItem) => {
    if (item.playable) {
      playVod(setPlayerState, activeItem);
    } else {
      pushNavigation(setVodState, vod, item.id);
    }
  };

  const renderItem = ({ item, itemIndex, rowIndex, sliderRef }) => {
    return (
      <Item
        item={item}
        rowIndex={rowIndex}
        itemIndex={itemIndex}
        sliderRef={sliderRef}
        onPress={() => handlePress(null, item, { item, rowIndex, itemIndex: itemIndex, focusKey: `vod_${rowIndex}_${itemIndex}` })}
      />
    );
  };

  return (
    <View style={styles.row}>
      <Text style={styles.rowTitle}>{row.title}</Text>
      <View style={{ flex: 1 }}>
        <FlatList
          ref={sliderRef}
          showsHorizontalScrollIndicator={false}
          horizontal={true}
          windowSize={2}
          initialNumToRender={5}
          data={row.items}
          getItemLayout={(data, index) => {
            return { length: ITEM_WIDTH, offset: ITEM_WIDTH * index, index };
          }}
          renderItem={({ item, index: itemIndex }) => renderItem({ item, itemIndex, rowIndex, sliderRef })}
        />
      </View>
    </View>
  );
};

export default hasWebFocusableUI ? withFocusable()(CategorySlider) : CategorySlider;
