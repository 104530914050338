import React from "react";
import { View, ImageBackground, StyleSheet } from "react-native";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import { Text, ProgressBar } from "react-native-paper";
import { getScaledValue } from "@rnv/renative";

const itemContainerWidth = 490;
const itemWidth = 440;
const itemHeight = 248;
const margin = (itemContainerWidth - itemWidth) / 2;

const styles = StyleSheet.create({
  contentContainer: {
    marginRight: getScaledValue(50 / 2),
    marginTop: getScaledValue(margin / 2),
    marginBottom: getScaledValue(margin / 2),
    position: "relative"
  },
  item: {
    width: getScaledValue(itemWidth / 2),
    height: getScaledValue(itemHeight / 2),
    borderRadius: getScaledValue(20 / 2),
    overflow: "hidden",
    borderWidth: getScaledValue(3 / 2),
    borderColor: "rgba(211, 211, 211, 0)"
  },
  itemFocused: {
    borderColor: "rgba(211, 211, 211, 1)"
  },
  iconContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  infoContainer: {
    position: "absolute",
    left: getScaledValue(20 / 2),
    bottom: getScaledValue(20 / 2),
    height: getScaledValue(42 / 2)
  },
  seriesTitle: {
    fontSize: getScaledValue(14 / 2),
    color: "rgba(255, 255, 255, 0.5)",
    fontWeight: "600"
  },
  episodeTitle: {
    fontSize: getScaledValue(16 / 2),
    lineHeight: getScaledValue(24 / 2),
    letterSpacing: -1,
    fontWeight: "700"
  }
});

const Content = ({ innerRef, item, active, hovered, isLoading }: any) => {
  return (
    <View ref={innerRef} style={styles.contentContainer}>
      <ImageBackground style={[styles.item, (active || hovered) && styles.itemFocused]} resizeMode="cover" source={{ uri: item.tileImageUrl }}>
        {active && (
          <View style={styles.iconContainer}>
            {isLoading ? (
              <ProgressBar indeterminate style={{ width: getScaledValue(40 / 2) }} />
            ) : item.playable ? (
              <View />
            ) : (
              <Icon name="play-box-multiple" size={getScaledValue(32 / 2)} />
            )}
          </View>
        )}
        <View style={styles.infoContainer}>
          <Text style={styles.seriesTitle}>{item.title}</Text>
          <Text style={styles.episodeTitle}>{item.episodeTitle}</Text>
        </View>
      </ImageBackground>
    </View>
  );
};

export default React.memo(Content, (prevProps, nextProps) => {
  return prevProps.active === nextProps.active;
});
