import React, { useEffect } from "react";
import { View } from "react-native";
import { Router, Routes, Route } from "../components/Router";
import ScreenHome from "../screens/Vod";
import { initNavigation } from "@noriginmedia/react-spatial-navigation";
import ScreenLogin from "../screens/Auth/Login";
import ScreenQrLogin from "../screens/Auth/QrLogin";
import ScreenAiringNow from "../screens/AiringNow";
import AuthGuard from "../components/AuthGuard";
import GuestGuard from "../components/GuestGuard";
import ScreenContentFeed from "../screens/ContentFeed";
import ScreenLinkTv from "../screens/Auth/LinkTv";
import Layout from "../layouts";
import useExit from "../hooks/useExit";
import { hasWebFocusableUI } from "../config";
import "../components/GlobalStyles";

if (hasWebFocusableUI) {
  initNavigation({
    debug: false,
    visualDebug: false,
    nativeMode: false
  });
}

const App = () => {
  if (hasWebFocusableUI) {
    useEffect(() => {
      // Required for tizen
      if (window.focus) window.focus();
    }, []);
  }

  useExit();

  return (
    <View style={hasWebFocusableUI ? { height: "100vh" } : { flex: 1 }}>
      <Routes>
        <Route path="link" element={<ScreenLinkTv />} />

        <Route path="/auth" element={<GuestGuard />}>
          {/* <Route path="code-login" element={<ScreenLogin />} /> */}
          <Route path="login" element={<ScreenQrLogin />} />
        </Route>

        <Route path="/" element={<AuthGuard />}>
          <Route path="/" element={<Layout />}>
            <Route path="content-feed" element={<ScreenContentFeed />} />
            <Route path="airing-now" element={<ScreenAiringNow />} />
            <Route path="" element={<ScreenHome />} />
          </Route>
        </Route>
      </Routes>
    </View>
  );
};

export default () => (
  <Router>
    <App />
  </Router>
);
