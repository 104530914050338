import React, { memo } from "react";
import Svg, { Path, Defs, LinearGradient, Stop } from "react-native-svg";

const ProfileActive = (props) => (
  <Svg width={props.width} height={props.height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="M21.999 8.667a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z" stroke="#fff" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
    <Path d="M21.999 8.667a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z" stroke="url(#a)" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
    <Path
      d="M15.999 17.334c-5.347 0-9.774 3.54-10.547 8.158-.155.923.223 1.847 1.062 2.262 1.538.76 4.52 1.58 9.485 1.58 4.964 0 7.946-.82 9.484-1.58.84-.415 1.218-1.34 1.063-2.262-.773-4.618-5.2-8.158-10.547-8.158Z"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M15.999 17.334c-5.347 0-9.774 3.54-10.547 8.158-.155.923.223 1.847 1.062 2.262 1.538.76 4.52 1.58 9.485 1.58 4.964 0 7.946-.82 9.484-1.58.84-.415 1.218-1.34 1.063-2.262-.773-4.618-5.2-8.158-10.547-8.158Z"
      stroke="url(#b)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Defs>
      <LinearGradient id="a" x1={29.131} y1={29.56} x2={2.739} y2={3.401} gradientUnits="userSpaceOnUse">
        <Stop stopColor="#D67BBC" />
        <Stop offset={1} stopColor="#1E58F0" />
      </LinearGradient>
      <LinearGradient id="b" x1={29.131} y1={29.56} x2={2.739} y2={3.401} gradientUnits="userSpaceOnUse">
        <Stop stopColor="#D67BBC" />
        <Stop offset={1} stopColor="#1E58F0" />
      </LinearGradient>
    </Defs>
  </Svg>
);

const Memo = memo(ProfileActive);
export default Memo;
