import { createSlice } from "@reduxjs/toolkit";
import axios from "../utils/axios";

const initialState = {
  data: null
};

const slice = createSlice({
  name: "player",
  initialState,
  reducers: {
    playChannel(state, action) {
      state.data = action.payload;
    },
    playRecording(state, action) {
      state.data = action.payload;
    },
    playVod(state, action) {
      state.data = action.payload;
    },
    stop(state, action) {
      state.data = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const playChannel = (id) => async (dispatch) => {
  //   const response = await axios.get("/playbackAuthenticated?channelId=" + id);
  //   dispatch(slice.actions.playChannel(response.data));
};

export const playRecording = (recording) => async (dispatch) => {
  //   const response = await axios.get(`/recordingPlayback?recordingId=${recording.id}`);
  //   dispatch(slice.actions.playRecording(response.data));
};

export const playVod = (vod) => async (dispatch) => {
  const response = await axios.get(`/playback/vod/${vod.id}`);
  if(vod.previewUrl) {
    response.data.url = vod.previewUrl;
  }
  dispatch(slice.actions.playVod(response.data));
};

export const stop = () => async (dispatch) => {
  dispatch(slice.actions.stop(null));
};

export default slice;
