import { atom } from "recoil";
import { getRecoil, setRecoil } from "recoil-nexus";
import axios from "../utils/axios";
import _ from "lodash";

export const vodState = atom({
  key: "vod",
  default: {
    navigations: null,
    navigationsCache: {}
  },
  dangerouslyAllowMutability: true
});

export const pushNavigation = (setState, state, id) => {
  if (id === null) {
    gotoNavigationPath(setState, state, []);

    return;
  }

  let curNavigations = state.navigations;
  const paths = curNavigations.map((n) => n.id);
  paths.push(id);
  gotoNavigationPath(setState, state, paths);
};

export const popNavigation = (setState, state) => {
  let curNavigations = state.navigations;
  const paths = curNavigations.map((n) => n.id);
  paths.pop();
  gotoNavigationPath(setState, state, paths);
};

export const gotoNavigationPath = async (setState, state, paths) => {
  let curNavigations = state.navigations;
  let curNavigationsCache = state.navigationsCache;

  let homeNavigation;
  if (!curNavigations) {
    const { data } = await axios.get("/navigation");
    const navigation = { id: null, ...data };
    homeNavigation = navigation;
  } else {
    homeNavigation = curNavigations[0];
  }

  const newNavigations = [homeNavigation];
  const newNavigationsCache = { ...curNavigationsCache };

  let ids = paths.filter((n) => n);
  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    let navigation = curNavigationsCache[id];
    if (!navigation) {
      const response = await axios.get(`/navigation/${id}`);
      navigation = { id, ...response.data };
    }

    newNavigations.push(navigation);
    newNavigationsCache[id] = navigation;
  }

  let firstVod;
  let focusedVod = _.last(newNavigations)?.focusedVod;
  if (focusedVod) {
    firstVod = focusedVod.item;
  } else {
    firstVod = newNavigations?.[newNavigations.length - 1].rows?.[0].items?.[0];
    focusedVod = { item: firstVod, rowIndex: 0, itemIndex: 0 };
  }

  setState({ navigations: newNavigations, navigationsCache: newNavigationsCache });
};

export const setSelectedVod = (item, rowIndex, itemIndex) => {
  const state: any = getRecoil(vodState);
  state.navigations[state.navigations.length - 1].focusedVod = { item, rowIndex, itemIndex };
  setRecoil(vodState, { ...state });
};
