import React from "react";
import { ViewProps } from "react-native";
import Animated, { useAnimatedStyle, withTiming } from "react-native-reanimated";

// https://github.com/marklawlor/nativewind/issues/45
export type OpacityViewProps = ViewProps & {
  opacity: number;
  config?: any;
};

const AnimatedViewOpacity = ({ opacity, config = {}, style = {}, ...rest }: OpacityViewProps) => {
  const rStyle = useAnimatedStyle(() => {
    return {
      opacity: withTiming(opacity, config)
    };
  }, [opacity]);

  return <Animated.View style={[{ flex: 1 }, style, rStyle]} {...rest} />;
};

export default AnimatedViewOpacity;
