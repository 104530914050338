import { atom } from "recoil";

export const authState = atom({
  key: "auth",
  default: {
    stremiumUser: {}
  }
});

export const setUser = async (setState, user) => {
  setState({ stremiumUser: user });
};
