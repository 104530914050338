import React, { memo } from "react";
import Svg, { G, Path } from "react-native-svg";

const Profile = (props) => (
  <Svg width={props.width} height={props.height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <G opacity={0.5} stroke="#CCD4E9" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
      <Path d="M21.999 8.667a6 6 0 1 1-12 0 6 6 0 0 1 12 0ZM15.999 17.333c-5.347 0-9.774 3.54-10.547 8.159-.155.922.223 1.847 1.062 2.261 1.538.76 4.52 1.58 9.485 1.58 4.964 0 7.946-.82 9.484-1.58.84-.414 1.218-1.338 1.063-2.261-.773-4.618-5.2-8.159-10.547-8.159Z" />
    </G>
  </Svg>
);

const Memo = memo(Profile);
export default Memo;
