import { getScaledValue } from "@rnv/renative";
import React, { useEffect, useState } from "react";
import { StyleSheet, View, Image, Dimensions } from "react-native";
import { Text } from "react-native-paper";

const width = Dimensions.get("window").width;

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    marginBottom: getScaledValue(16),
    alignItems: "center"
  },
  seriesLogoContainer: {
    width: getScaledValue(44),
    height: getScaledValue(66),
    marginRight: getScaledValue(8),
    backgroundColor: "#1c2025",
    borderRadius: getScaledValue(8),
    overflow: "hidden"
  },
  seriesLogo: {
    resizeMode: "cover",
    flex: 1
  },
  seriesTitle: {
    fontSize: getScaledValue(11),
    fontFamily: "OpenSans-700",
    textAlignVertical: "top",
    color: "rgba(255, 255, 255, 0.5)",
    width: getScaledValue(width / 2)
  },
  episodeTitle: {
    fontSize: getScaledValue(46 / 2),
    fontFamily: "OpenSans-700",
    letterSpacing: -getScaledValue(2.36842 / 2),
    lineHeight: getScaledValue(56 / 2),
    // width: getScaledValue(width / 4)
    width: "50%"
  }
});

const SeriesLogo = ({ uri }) => {
  const [style, setStyle] = useState({});

  useEffect(() => {
    Image.getSize(uri, (width, height) => {
      if (width === height) {
        setStyle({ padding: 0 });
      }
    });
  }, []);

  return (
    <View style={[styles.seriesLogoContainer, style]}>
      <Image style={styles.seriesLogo} source={{ uri: uri }} />
    </View>
  );
};

const EpisodeHeader = ({ seriesLogo, seriesTitle, episodeTitle }) => {
  return (
    <View style={styles.headerContainer}>
      <SeriesLogo uri={seriesLogo} />
      <View style={{ flex: 1 }}>
        {seriesTitle && (
          <Text style={episodeTitle ? styles.seriesTitle : styles.episodeTitle} numberOfLines={2}>
            {seriesTitle}
          </Text>
        )}
        { episodeTitle && <Text numberOfLines={2} style={styles.episodeTitle}>
            {episodeTitle}
          </Text>
        }
      </View>
    </View>
  );
};

export default EpisodeHeader;
