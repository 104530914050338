import { atom } from "recoil";
import axios from "../utils/axios";

export const playerState = atom({
  key: "player",
  default: {
    data: null
  }
});

export const playVod = async (setState, vod) => {
  const response = await axios.get(`/playback/vod/${vod.id}`);
  setState({ data: response.data });
};

export const stop = async (setState) => {
  setState({ data: null });
};
