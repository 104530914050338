import { useEffect } from "react";
import _ from "lodash";

const listenerMap = new Map();
document.addEventListener("keydown", (e) => {
  if (e.key === "Escape" || e.key === "Backspace" || e.keyCode === 10009 || e.keyCode === 461) {
    for (let listener of _.sortBy(Array.from(listenerMap.values()), (n) => n.priority).reverse()) {
      const isHandled = listener.callback();

      if (isHandled) {
        break;
      }
    }
  }
});

const useBackKey = (callback, priority = 2) => {
  useEffect(() => {
    listenerMap.set(callback, { callback, priority });

    return () => {
      listenerMap.delete(callback);
    };
  }, [callback]);
};

export default useBackKey;
