import React from "react";
import { StyleSheet, View } from "react-native";
import { withFocusable } from "@noriginmedia/react-spatial-navigation";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    zIndex: -999,
    width: "100%",
    height: "100%"
  }
});

const FocusablePlaceholder = (props) => {
  return <View {...props} style={[styles.container, props.style]}></View>;
};

export default withFocusable()(FocusablePlaceholder);
