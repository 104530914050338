import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stremiumUser: {}
};

const slice = createSlice({
  name: "stremiumUser",
  initialState,
  reducers: {
    setUser(state, action) {
      state.stremiumUser = {
        ...action.payload
      };
    }
  }
});

export const reducer = slice.reducer;

export default slice;
