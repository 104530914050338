import React, { useRef, useState } from "react";
import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import Content from "./Content";
import { pushNavigation, setSelectedVod, vodState } from "../../../atoms/vod";
import { playerState, playVod } from "../../../atoms/player";
import { useRecoilState } from "recoil";
import _ from "lodash";
import { Pressable } from "react-native";
import { useFocusContext } from "../../../contexts/FocusContext";

const FocusableVod = withFocusable()(Content);

export default ({ item, itemIndex, rowIndex, sliderRef }) => {
  const elementRef = useRef<any>();
  const [active, setActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [{}, setPlayerState] = useRecoilState<any>(playerState);
  const [vod, setVodState] = useRecoilState<any>(vodState);
  const { setFocus } = useFocusContext();

  const navigations = vod.navigations;
  const curNavigation = _.last(navigations);
  let activeItem = curNavigation?.focusedVod?.item;

  const handleFocus = () => {
    // sliderRef.current.scrollToIndex({ index: itemIndex, viewPosition: itemIndex > 0 ? 0.5 : 0 });
    elementRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "center"
    });

    setActive(true);
    setSelectedVod(item, rowIndex, itemIndex);
  };

  const handleBlur = () => {
    setActive(false);
  };

  const handlePress = () => {
    console.log(item);
    if (item.playable) {
      playVod(setPlayerState, activeItem);
    } else {
      pushNavigation(setVodState, vod, item.id);
    }
  };

  const focusKey = `vod_${rowIndex}_${itemIndex}`;

  return (
    <Pressable
      onFocus={() => {
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
      }}
      onPress={() => {
        setFocus(focusKey);
      }}
    >
      {({ hovered }) => (
        <FocusableVod
          innerRef={elementRef}
          focusKey={focusKey}
          onBecameFocused={handleFocus}
          onBecameBlurred={handleBlur}
          onEnterPress={handlePress}
          item={item}
          active={active}
          hovered={hovered}
          isLoading={isLoading}
        />
      )}
    </Pressable>
  );
};
