import { atom } from "recoil";
import { setRecoil } from "recoil-nexus";

export const activeEpisodePreviewState = atom({
  key: "activeEpisodePreview",
  default: null
});

export const setActiveEpisodePreview = (episode) => {
  setRecoil(activeEpisodePreviewState, episode);
};
