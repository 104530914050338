import axios from "axios";
import adapter from "axios/lib/adapters/xhr";
import { auth } from "../lib/firebase";

const axiosInstance = axios.create({ baseURL: "https://fireside.api.stremium.com", adapter });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    return Promise.reject((error.response && { status: error.response.status, ...error.response.data }) || "Something went wrong");
  }
);

axiosInstance.interceptors.request.use(async (config: any) => {
  const user = auth.currentUser;
  try {
    if (user) {
      const token = await user.getIdToken();
      config.headers.Authorization = token;
    }
  } catch (err) {
    console.log(err);
  }

  return config;
});

export default axiosInstance;
