import React, { useEffect, useRef } from "react";
import { View, FlatList } from "react-native";
import { useRecoilValue } from "recoil";
import LoadingScreen from "../../components/LoadingScreen";
import { contentFeedState, getFeeds } from "../../atoms/contentFeed";
import Feed from "./Feed";
import { hasWebFocusableUI } from "../../config";
import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import { ITEM_HEIGHT } from "./constants";

const ScreenContentFeedV2 = ({ setFocus }) => {
  const channels = useRecoilValue<any>(contentFeedState);
  const activeIndexRef = useRef<any>(0);
  const sliderRef = useRef<any>();

  useEffect(() => {
    getFeeds();
  }, []);

  if (hasWebFocusableUI) {
    useEffect(() => {
      if (!channels) return;

      setFocus("feed_0");

      let timerId = setInterval(() => {
        setFocus(`feed_${activeIndexRef.current + 1}`);
      }, 10 * 1000);

      const handleKeyPress = (e) => {
        clearInterval(timerId);
      };

      window.document.addEventListener("keydown", handleKeyPress);

      return () => {
        clearInterval(timerId);
        window.document.removeEventListener("keydown", handleKeyPress);
      };
    }, [channels]);
  }

  if (!channels) {
    return <LoadingScreen />;
  }

  const handleItemFocus = (index) => {
    activeIndexRef.current = index;
    sliderRef.current.scrollToIndex({ index });
  };

  const getItemLayout = (data, index) => ({ length: ITEM_HEIGHT, offset: ITEM_HEIGHT * index, index });

  const renderItem = ({ item, index }) => {
    return <Feed feed={item} itemIndex={index} onFocus={() => handleItemFocus(index)} />;
  };

  return (
    <View style={{ flex: 1 }}>
      <FlatList
        ref={sliderRef}
        showsVerticalScrollIndicator={true}
        scrollEnabled={false}
        scrollEventThrottle={16}
        data={channels}
        getItemLayout={getItemLayout}
        renderItem={renderItem}
      />
    </View>
  );
};

export default hasWebFocusableUI ? withFocusable()(ScreenContentFeedV2) : ScreenContentFeedV2;
