import React from "react";
import { StyleSheet } from "react-native";
import { View, Text, Image } from "../../../components/Tw";
// import { Text } from "react-native-paper";
import { getScaledValue } from "@rnv/renative";
import moment from "moment";
import BackgroundGradient from "./BackgroundGradient";

const styles = StyleSheet.create({
  episodeInfoContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "rgba(217, 217, 217, 0.02)",
    borderRadius: getScaledValue(8 / 2),
    overflow: "hidden",
    marginLeft: getScaledValue(8 / 2),
    padding: getScaledValue(8 / 2)
  }
});

const Timeline = ({ brandColor, darkerBrandColor, currentEpisode, upcomingEpisodes }) => {
  const renderRow = (episode, indent, isLive = false) => {
    return (
      <View className="flex-row mt-2.5">
        <Image className="rounded-full" style={{ width: 56, height: 56, marginRight: 8 }} source={{ uri: episode.primaryImageUrl }} />
        <View style={[styles.episodeInfoContainer, { marginLeft: indent * 50 }]}>
          <BackgroundGradient brandColor={brandColor} darkerBrandColor={darkerBrandColor} />
          <Text className="font-open-sans font-bold text-white text-xs opacity-40">{isLive ? "On Now" : moment(episode.airTime).format("h:mma")}</Text>
          <Text className="text-white text-xs font-open-sans font-bold ml-2" style={!isLive && { opacity: 0.4 }}>
            {episode.title}
          </Text>
        </View>
      </View>
    );
  };

  return (
    <>
      {renderRow(currentEpisode, 0, true)}
      {renderRow(upcomingEpisodes[0], 1)}
      {renderRow(upcomingEpisodes[1], 2)}
    </>
  );
};

export default Timeline;
