import React, { memo } from "react";
import Svg, { Path, Defs, LinearGradient, Stop } from "react-native-svg";

const AiringNowActive = (props) => (
  <Svg width={props.width} height={props.height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M22.666 2.667 17.06 8.273a1.5 1.5 0 0 1-2.121 0L9.333 2.667m.794 6.666h11.745c2.61 0 3.914 0 4.913.51a4.667 4.667 0 0 1 2.039 2.039c.509.998.509 2.305.509 4.918v5.067c0 2.613 0 3.92-.509 4.918a4.666 4.666 0 0 1-2.04 2.04c-.998.508-2.303.508-4.912.508H10.127c-2.61 0-3.915 0-4.913-.508a4.667 4.667 0 0 1-2.04-2.04c-.508-.998-.508-2.305-.508-4.918V16.8c0-2.614 0-3.92.509-4.918a4.667 4.667 0 0 1 2.04-2.04c.997-.509 2.302-.509 4.912-.509Z"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M22.666 2.667 17.06 8.273a1.5 1.5 0 0 1-2.121 0L9.333 2.667m.794 6.666h11.745c2.61 0 3.914 0 4.913.51a4.667 4.667 0 0 1 2.039 2.039c.509.998.509 2.305.509 4.918v5.067c0 2.613 0 3.92-.509 4.918a4.666 4.666 0 0 1-2.04 2.04c-.998.508-2.303.508-4.912.508H10.127c-2.61 0-3.915 0-4.913-.508a4.667 4.667 0 0 1-2.04-2.04c-.508-.998-.508-2.305-.508-4.918V16.8c0-2.614 0-3.92.509-4.918a4.667 4.667 0 0 1 2.04-2.04c.997-.509 2.302-.509 4.912-.509Z"
      stroke="url(#a)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Defs>
      <LinearGradient id="a" x1={32.547} y1={29.56} x2={6.793} y2={-2.606} gradientUnits="userSpaceOnUse">
        <Stop stopColor="#D67BBC" />
        <Stop offset={1} stopColor="#1E58F0" />
      </LinearGradient>
    </Defs>
  </Svg>
);

const Memo = memo(AiringNowActive);
export default Memo;
