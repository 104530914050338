/*
If you are using server-side rendering, remember that this component should be loaded on client-side
shaka player needs to be loaded on client-side, loading it on server-side may lead to error or undesired results
*/

/*
importing dependencies and CSS file(s) required for UI customization
*/
import React from "react";
import "shaka-player/dist/controls.css";

const shaka = require("shaka-player/dist/shaka-player.ui.js");

//Creating class component
class VideoPlayer extends React.PureComponent {
  constructor(props) {
    super(props);

    //Creating reference to store video component on DOM
    this.videoComponent = this.props.playerRef ?? React.createRef();

    //Initializing reference to error handlers
    this.onErrorEvent = this.onErrorEvent.bind(this);
    this.onError = this.onError.bind(this);
  }

  onErrorEvent(event) {
    // Extract the shaka.util.Error object from the event.
    this.onError(event.detail);
  }

  onError(error) {
    // Log the error.
    console.error("Error code", error.code, "object", error);
  }

  componentWillUnmount() {
    if (typeof this.player !== "undefined" && this.player !== null) {
      //player.resetConfiguration();
      this.player.unload();
      this.player.destroy();
      //player = null;
    }
  }

  componentDidMount() {
    //Getting reference to video and video container on DOM
    const video = this.videoComponent.current;

    //Initialize shaka player
    this.player = new shaka.Player(video);

    // Listen for error events.
    this.player.addEventListener("error", this.onErrorEvent);

    const responseData = this.props.responseData;
    if (responseData.drmInfo) {
      this.player.getNetworkingEngine().registerRequestFilter((type, request) => {
        // Only add headers to license requests:
        if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
          request.headers = { ...request.headers, ...responseData.drmInfo.drmKeyRequestProperties };
          // This is the specific header name and value the server wants:
          //request.headers['Content-Type'] = 'text/xml';
          //request.headers['Authorization'] = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjAzNzIwZDQ3LTIxMTItNGRiMy1iOTkxLWFjODUwN2QwZDVlYiJ9.eyJqdGkiOiIwNGMwYzM2OC0zMTJjLTQ2NTgtYmIzYS1hZTVkZmM1MjI2NDgiLCJhaWQiOiJ0d2MiLCJzdWIiOiJMWE0uODQ0ODo4NDQ4MjAwMjgwNzkzNDcxIiwiaWF0IjoxNjAyODc0MDUzLCJleHAiOjE2MDI4NzQzNTMsImlzcyI6ImlwdnMiLCJlbnQiOlt7ImVwaWQiOiJMaW5lYXIiLCJiaWQiOiJMaW5lYXIifV0sImlzZSI6dHJ1ZX0.ex0U8oslKYZFAOo10yERb7g8GlyVG8ZeRvmL8ysJEBM';
        }
      });

      this.player.configure({
        drm: {
          servers: {
            "com.widevine.alpha": responseData.drmInfo.drmLicenseUrl
          }
        }
      });
    }

    // Try to load a manifest.
    // This is an asynchronous process.
    this.player
      .load(responseData.url)
      .then(() => {
        console.log("[Shaka] Attempting autoplay");
        const playPromise = video.play();
        if (playPromise !== undefined) {
          playPromise
            .then(function () {
              console.log("Automatic playback started!");
            })
            .catch(function (error) {
              console.log("Automatic playback failed: " + error);
            });
        }
      })
      .catch(this.onError); // onError is executed if the asynchronous load fails.
  }

  render() {
    return (
      <video
        ref={this.videoComponent}
        autoPlay
        controls
        disablePictureInPicture
        controlsList="nodownload nofullscreen noplaybackrate"
        style={{ position: "fixed", top: 0, bottom: 0, left: 0, right: 0, zIndex: 9999, width: "100%", height: "100%" }}
      />
    );
  }
}

export default VideoPlayer;
