import React, { memo } from "react";
import Svg, { Path } from "react-native-svg";

const ContentFeed = (props) => (
  <Svg width={props.width} height={props.height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      opacity={0.5}
      d="M14.78 2.273 3.445 10.999a2 2 0 0 0-.78 1.585v16.083a2 2 0 0 0 2 2h4.829a2 2 0 0 0 2-2V18.124a2 2 0 0 1 2-2h4.919a2 2 0 0 1 2 2v10.543a2 2 0 0 0 2 2h4.919a2 2 0 0 0 2-2V12.584a2 2 0 0 0-.78-1.585L17.219 2.273a2 2 0 0 0-2.44 0Z"
      stroke="#CCD4E9"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

const Memo = memo(ContentFeed);
export default Memo;
