import React, { useRef } from "react";
import { StyleSheet, View, ImageBackground, Image, TouchableOpacity, ScrollView } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { Text } from "react-native-paper";
import { getScaledValue } from "@rnv/renative";
import { Notification } from "../../../components/Icons";
import moment from "moment";
import { ITEM_WIDTH } from "../constants";
import { LIVE_NOW_BLUR, LIVE_NOW_ANIMATION_NO_BLUR } from "../../../config";
import FocusableButton from "../../../components/Focusable/FocusableButton";
import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import { setActiveEpisodePreview } from "../../../atoms/airing-now/activeEpisodePreview";
import { isFactorMobile } from "@rnv/renative";

const tileContainerWidth = ITEM_WIDTH * 0.6;
const scale = tileContainerWidth / getScaledValue(1040 / 2);

const containerWidth = getScaledValue(300 / 2) * scale;
const containerHeight = getScaledValue(450 / 2) * scale;
const upcomingContainerWidth = getScaledValue(267 / 2) * scale;
const upcomingContainerHeight = getScaledValue(450 / 2) * scale;
const borderRadius = getScaledValue(10 / 2) * scale;
const spacing = getScaledValue(24 / 2) * scale;

const styles = StyleSheet.create({
  episodesContainer: {
    flexDirection: "row"
  },
  title: {
    fontFamily: "OpenSans-700",
    fontSize: getScaledValue(30 / 2),
    marginBottom: getScaledValue(20 / 2)
  },
  airingNowContainer: {
    width: containerWidth,
    height: containerHeight,
    borderRadius: borderRadius,
    overflow: "hidden"
  },
  upcomingContainer: {
    width: upcomingContainerWidth,
    height: upcomingContainerHeight,
    borderRadius: borderRadius,
    overflow: "hidden",
    marginRight: spacing
  },
  liveNowIcon: {
    position: "absolute",
    left: 0
  },
  notiDateContainer: {
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  },
  date: {
    fontFamily: "OpenSans-700",
    fontSize: getScaledValue(18 / 2) * scale,
    opacity: 0.5,
    letterSpacing: -0.04
  },
  upcomingEpisodeImage: {
    width: upcomingContainerWidth,
    height: getScaledValue(400 / 2) * scale,
    borderRadius: borderRadius,
    overflow: "hidden"
  }
});

const Tiles = ({ currentEpisode, upcomingEpisodes, defaultFocusKey }) => {
  const sliderRef = useRef<any>();

  const renderAiringNow = () => {
    return (
      <FocusableButton
        style={styles.airingNowContainer}
        focusKey={defaultFocusKey}
        onArrowPress={(direction, props) => {
          // TODO: blockNavigationOut: true seems not work
          if (direction === "up" || direction === "down") {
            return false;
          }
        }}
        onBecameFocused={() => {
          setActiveEpisodePreview(currentEpisode);
        }}
      >
        <ImageBackground style={styles.airingNowContainer} source={{ uri: currentEpisode.primaryImageUrl }}>
          <View style={styles.liveNowIcon}>
            <ImageBackground source={LIVE_NOW_BLUR}>
              <Image style={{ width: getScaledValue(198 / 3), height: getScaledValue(76.5 / 3) }} source={LIVE_NOW_ANIMATION_NO_BLUR} />
            </ImageBackground>
          </View>
        </ImageBackground>
      </FocusableButton>
    );
  };

  const renderUpcoming = ({ item, index }) => {
    return (
      <FocusableButton
        key={index}
        style={styles.upcomingContainer}
        // TODO: Patch: Cannot navigate back to airing now item + blockNavigationOut: true seems not work
        onArrowPress={(direction, props) => {
          if (direction === "up" || direction === "down" || (direction === "right" && index === upcomingEpisodes.length - 1)) {
            return false;
          }

          if (index == 0 && direction === "left") {
            props.setFocus(defaultFocusKey);
          }
        }}
        onBecameFocused={() => {
          // TODO: Cannot center with viewPosition, using viewOffset
          sliderRef.current.scrollToIndex({ index, viewPosition: 0 });
          setActiveEpisodePreview(item);
        }}
      >
        <View style={{ height: upcomingContainerHeight }}>
          <View style={styles.notiDateContainer}>
            <Text style={styles.date}>{moment(item.airTime).calendar(null, { sameDay: "[Today] h:mma", nextDay: "[Tomorrow] h:mma" })}</Text>
            <View style={{ marginLeft: getScaledValue(6 / 2) }}>
              <Notification />
            </View>
          </View>
          <ImageBackground style={styles.upcomingEpisodeImage} source={{ uri: item.primaryImageUrl }} />
        </View>
      </FocusableButton>
    );
  };

  return (
    <View style={styles.episodesContainer}>
      {!isFactorMobile && (
        <View style={{ marginRight: spacing }}>
          <Text style={styles.title}>Airing Now</Text>
          {renderAiringNow()}
        </View>
      )}

      <View style={{ flex: 1 }}>
        <Text style={styles.title}>Upcoming</Text>
        <FlatList
          ref={sliderRef}
          horizontal
          showsHorizontalScrollIndicator={false}
          // nestedScrollEnabled // https://stackoverflow.com/questions/57734315/how-can-i-have-nested-horizontal-scrollviews-in-react-native
          data={upcomingEpisodes}
          getItemLayout={(data, index) => {
            return { length: upcomingContainerWidth + spacing, offset: (upcomingContainerWidth + spacing) * index, index };
          }}
          renderItem={renderUpcoming}
        />
      </View>
    </View>
  );
};

export default withFocusable()(Tiles);
