import { Dimensions } from "react-native";
const { width, height } = Dimensions.get("window");

export const ITEM_WIDTH = width * 0.8;
export const ITEM_HEIGHT = height; //* 0.8;
export const ITEM_BORDER_RADIUS = 20;
export const ITEM_PADDING_VERTICAL = height * 0.1;

export const PADDING_HORIZONTAL = width * 0.1;
export const PADDING_VERITCAL = 0; //height * 0.1;

export const ROW_TRAN_Y = -ITEM_PADDING_VERTICAL * 1.5;

export const FULL_SCREEN_TIMER_DURATION = 30 * 1000;
