import { getScaledValue } from "@rnv/renative";
import React from "react";
import { View, Text, StyleSheet } from "react-native";
import EpisodeHeader from "../../components/EpisodeHeader";

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 0
  },
  title: {
    fontSize: getScaledValue(46 / 2),
    fontWeight: "bold",
    textShadowColor: "rgba(0, 0, 0, 0.75)",
    textShadowOffset: { width: getScaledValue(2 / 2), height: getScaledValue(2 / 2) },
    textShadowRadius: getScaledValue(10 / 2),
    maxWidth: getScaledValue(575 / 2)
  },
  text: {
    color: "#FFF",
    fontSize: getScaledValue(16 / 2)
  },
  description: {
    width: getScaledValue(650 / 2),
    fontFamily: "WorkSans-500",
    fontSize: getScaledValue(18 / 2),
    lineHeight: getScaledValue(30 / 2),
    textShadowColor: "rgba(0, 0, 0, 0.75)",
    textShadowOffset: { width: getScaledValue(2 / 2), height: getScaledValue(2 / 2) },
    textShadowRadius: getScaledValue(10 / 2)
  }
});

const VodDetails = ({ item }) => {
  if (!item) return null;

  return (
    <View style={[styles.headerContainer]}>
      <EpisodeHeader episodeTitle={item.title} seriesTitle={item.episodeTitle} seriesLogo={item.tileImageUrl} />
      <Text numberOfLines={3} ellipsizeMode="tail" style={[styles.text, styles.description]}>
        {item.description}
      </Text>
    </View>
  );
};

export default VodDetails;
